import React from 'react';
import Helmet from 'react-helmet';
import { Flex, Box, Text } from 'rebass';
import GatsbyLink from 'gatsby-link';
import Layout from '../components/Layout';

function Home() {
  return (
    <Layout>
      <Helmet title="Not found" defer={false} />
      <Flex mt={6} px={[3, null, 4]} justifyContent="center">
        <Box>
          <Text
            as="h1"
            fontSize={4}
            fontFamily="sans"
            color="primary"
            lineHeight={1}
          >
            <h1>Page not found</h1>
            <p>The page you requested doesn't exist. If you believe this to be an error, <GatsbyLink to="/contact">let us know</GatsbyLink>.</p>
          </Text>
          <Text as="p" fontFamily="sans" py={3} color="primary" lineHeight={1}>
            How about checking out the{' '}
            <Text as={GatsbyLink} to="/">
              first page
            </Text>
            ?
          </Text>
        </Box>
      </Flex>
    </Layout>
  );
}

export default Home;
